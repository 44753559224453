<template>
  <div
    v-if="visible"
    class="toast"
    :style="{ transform: transformStyle, opacity: opacityStyle }"
  >
    <slot>{{ message }}</slot>
  </div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      visible: false,
      message: "",
      transformStyle: "translateY(-100%) scale(0.8)", // 初始状态：略微缩小并位于顶部外部
      opacityStyle: 0, // 初始透明度
    };
  },
  methods: {
    show(message, duration = 3000) {
      this.message = message;
      this.visible = true;

      this.$nextTick(() => {
        // 动画进入：滑入并放大
        setTimeout(() => {
          this.transformStyle = "translateY(0) scale(1)";
          this.opacityStyle = 1;
        }, 50); // 短暂延迟以确保动画触发

        // 一段时间后隐藏
        setTimeout(() => {
          this.hide();
        }, duration);
      });
    },
    hide() {
      // 动画离开：滑出并缩小
      this.transformStyle = "translateY(-100%) scale(0.8)";
      this.opacityStyle = 0;

      // 动画结束后隐藏元素
      setTimeout(() => {
        this.visible = false;
      }, 300); // 动画时长
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 9999999;
}
</style>
