import Vue from "vue";
import ToastComponent from "./toast.vue";

const ToastConstructor = Vue.extend(ToastComponent);

let toastInstance;

function createToast() {
  toastInstance = new ToastConstructor();
  const vm = toastInstance.$mount();
  document.body.appendChild(vm.$el);
}

function showToast(message, duration = 3000) {
  if (!toastInstance) {
    createToast();
  }
  toastInstance.show(message, duration);
}

export default showToast;
