<template>
  <div
    v-if="visible"
    class="toast"
    :style="{ transform: transformStyle, opacity: opacityStyle }"
  >
    <slot>{{ message }}</slot>
  </div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      visible: false,
      message: "",
      transformStyle: "translate(-50%, -50%) scale(0.9)", // 初始位置及缩放
      opacityStyle: 0, // 初始透明度
    };
  },
  methods: {
    show(message, duration = 3000) {
      this.message = message;
      this.visible = true;
      this.$nextTick(() => {
        // 动画进入：滑入并放大
        this.transformStyle = "translate(-50%, -50%) scale(1)";
        this.opacityStyle = 1;

        // 一段时间后隐藏
        setTimeout(() => {
          this.hide();
        }, duration);
      });
    },
    hide() {
      // 动画离开：滑出并缩小
      this.transformStyle = "translate(-50%, -50%) scale(0.9)";
      this.opacityStyle = 0;

      // 动画结束后隐藏元素
      setTimeout(() => {
        this.visible = false;
      }, 300); // 动画时长
    },
  },
};
</script>

<style scoped>
.toast {
  position: fixed;
  top: 50%; /* 页面垂直居中 */
  left: 50%; /* 页面水平居中 */
  transform: translate(-50%, -50%); /* 水平+垂直居中 */
  background-color: #f9e749; /* 背景颜色 */
  color: #191919; /* 文字颜色 */
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 14px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
  z-index: 99999999;
  text-align: center; /* 文字居中 */
  max-width: 80%; /* 限制最大宽度 */
  word-break: break-word; /* 防止文字超出 */
}
</style>
