<template>
  <svg
    :width="svgWidth"
    height="44"
    ref="waveform"
    :viewBox="'0 0 ' + svgWidth + ' ' + svgHeight"
    preserveAspectRatio="none"
  ></svg>
</template>

<script>
// 音频波形组件
export default {
  name: "Waveform",
  props: {
    audioSrc: {
      type: String,
      required: false,
    },
    svgWidth: {
      type: Number,
      default: 450,
    },
    svgHeight: {
      type: Number,
      default: 37,
    },
    waveformPoints: {
      type: Number,
      default: 140,
    },
    maxWaveformHeight: {
      type: Number,
      default: 25,
    },
    barWidth: {
      type: Number,
      default: 0.8,
    },
    onComplete: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      waveformCache: null,
      audioContext: null, // 引入 AudioContext 实例
    };
  },
  methods: {
    async generateWaveform() {
      // 清空当前波形内容
      this.clearWaveform();

      if (this.waveformCache) {
        this.drawWaveform(this.waveformCache);
        return;
      }

      if (!this.audioSrc) return;

      if (!this.audioContext) {
        this.audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
      }

      try {
        const response = await fetch(this.audioSrc);
        const audioData = await response.arrayBuffer();
        const audioBuffer = await this.audioContext.decodeAudioData(audioData);
        const channelData = audioBuffer.getChannelData(0);

        const step = Math.floor(channelData.length / this.waveformPoints);
        let waveform = new Array(this.waveformPoints).fill(0);

        let maxAmplitude = 0;
        for (let i = 0; i < this.waveformPoints; i++) {
          let max = -Infinity;
          let min = Infinity;
          for (let j = 0; j < step; j++) {
            const value = channelData[i * step + j];
            if (value > max) max = value;
            if (value < min) min = value;
          }
          waveform[i] = (Math.abs(max) + Math.abs(min)) / 2;
          maxAmplitude = Math.max(maxAmplitude, waveform[i]);
        }

        if (maxAmplitude > 0) {
          waveform = waveform.map(
            (value) => (value / maxAmplitude) * this.maxWaveformHeight
          );
        }

        this.waveformCache = waveform;
        this.drawWaveform(waveform);
      } catch (err) {
        console.error("Failed to generate waveform:", err);
      }
    },

    drawWaveform(waveform) {
      const svg = this.$refs.waveform;
      const centerY = this.svgHeight / 2;
      const barWidth = this.svgWidth / waveform.length;

      svg.innerHTML = "";

      const drawChunk = (startIndex) => {
        const chunkSize = 10;
        const endIndex = Math.min(startIndex + chunkSize, waveform.length);

        for (let i = startIndex; i < endIndex; i++) {
          const value = waveform[i];
          const barHeight = value;
          const x = i * barWidth;
          const y1 = centerY - barHeight / 2;
          const y2 = centerY + barHeight / 2;

          const line = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "line"
          );
          line.setAttribute("x1", x);
          line.setAttribute("y1", y1);
          line.setAttribute("x2", x);
          line.setAttribute("y2", y2);
          line.setAttribute("stroke", "currentColor");
          line.setAttribute("stroke-width", this.barWidth);
          line.setAttribute("stroke-linecap", "round");
          svg.appendChild(line);
        }

        if (endIndex < waveform.length) {
          requestAnimationFrame(() => drawChunk(endIndex));
        } else {
          if (typeof this.onComplete === "function") {
            this.onComplete();
          } else {
            this.svgWidth !== 252 && this.$bus.$emit("complete");
          }
        }
      };

      drawChunk(0);
    },

    clearWaveform() {
      const svg = this.$refs.waveform;
      if (svg) {
        svg.innerHTML = "";
      }
      this.waveformCache = null;
    },
  },
  watch: {
    audioSrc: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.clearWaveform();
        } else {
          this.generateWaveform();
        }
      },
    },
  },
  beforeDestroy() {
    // 释放 AudioContext 资源
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = null;
    }
    this.clearWaveform();
  },
};
</script>
