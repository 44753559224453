<template>
  <transition name="dialog-fade">
    <div v-show="visible" class="dialog-overlay" @click="handleOverlayClick">
      <div @click.stop class="dialog-content">
        <slot></slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Dialog",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    visible() {
      return this.value;
    },
  },
  methods: {
    // 点击背景关闭弹窗
    handleOverlayClick() {
      this.$emit("close", false); // 关闭弹窗
    },
  },
};
</script>

<style scoped>
/* 弹窗的遮罩层 */
.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 909999;
  opacity: 1;
  /* visibility: hidden; */
}

/* 弹窗内容 */
.dialog-content {
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transform: scale(0.9); */
  transition: transform 0.3s ease;
}

/* Vue transition classes */
.dialog-fade-enter-active,
.dialog-fade-leave-active {
  transition: opacity 0.3s ease, visibility 0s 0.3s;
}

.dialog-fade-enter, 
.dialog-fade-leave-to /* .dialog-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  visibility: hidden;
}

.dialog-fade-enter-to {
  opacity: 1;
  visibility: visible;
}

.dialog-fade-enter-active .dialog-content {
  transform: scale(1);
}

.dialog-fade-leave-active .dialog-content {
  transform: scale(0.9);
}
</style>
