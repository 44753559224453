<template>
  <div class="music">
    
    <div v-if="projectBase&&projectBase.downloadUrl" class="top-btn">
      <!-- <div class="left">Open in app</div> -->
      <div class="right" @click="goToDownload">Download app</div>
    </div>
   
    <div v-else class="top-btn-replace">
    </div>
    <div class="bg-img-box">
      <img
        :class="['music-bg-img', { 'music-bg-img-playing': isPlaying }]"
        :src="projectBase.projectCoverImgUrl"
        alt=""
        @load="loadProjectCoverImgComplete"
      />
      <div :class="['disc', { 'disc-playing': isPlaying },{'node-dis':isLoadProjectCoverImgComplete},{'node-hidden':!isLoadProjectCoverImgComplete}]">
        <div class="disc-bg"></div>
        <img class="disc-img" src="../assets/img/playback-disc.png" alt="" />
        <!-- <img :class="['disc-img',{'node-dis':isLoadProjectCoverImgComplete},{'node-hidden':!isLoadProjectCoverImgComplete}]" src="../assets/img/playback-disc.png" alt="" /> -->
      </div>
    </div>
    <div class="main-play">
      <div class="main-left">
        <div>{{projectBase.projectName}}</div>
        <p><img src="@/assets/img/down.png" alt="" />{{projectBase.userName}} • {{projectBase.trackCount}} tracks • 0 sec</p>
      </div>
      <div v-if="list!=null && list.length>0" class="main-right" @click="playList">
        <svg
          v-show="!isPlaying"
          width="12"
          height="14"
          viewBox="0 0 12 14"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <path
            fill="#000"
            d="M1.37842 13.1206C1.69336 13.1206 1.95703 13.0107 2.29395 12.8203L10.9219 7.81787C11.5591 7.45166 11.8081 7.17334 11.8081 6.71924C11.8081 6.26514 11.5591 5.98682 10.9219 5.62061L2.29395 0.625488C1.95703 0.427734 1.69336 0.317871 1.37842 0.317871C0.785156 0.317871 0.367676 0.771973 0.367676 1.49707V11.9414C0.367676 12.6665 0.785156 13.1206 1.37842 13.1206Z"
          ></path>
        </svg>
        <svg
          v-show="isPlaying"
          width="11"
          height="14"
          viewBox="0 0 11 14"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <path
            fill="#000"
            d="M1.30371 13.9048H3.24609C4.03467 13.9048 4.43311 13.498 4.43311 12.7178V1.3042C4.43311 0.499023 4.03467 0.125488 3.24609 0.117188H1.30371C0.523438 0.117188 0.116699 0.523926 0.116699 1.3042V12.7178C0.108398 13.498 0.515137 13.9048 1.30371 13.9048ZM7.5874 13.9048H9.52979C10.3101 13.9048 10.7168 13.498 10.7168 12.7178V1.3042C10.7168 0.499023 10.3101 0.117188 9.52979 0.117188H7.5874C6.79883 0.117188 6.40039 0.523926 6.40039 1.3042V12.7178C6.40039 13.498 6.79883 13.9048 7.5874 13.9048Z"
          ></path>
        </svg>
      </div>
    </div>
    <div class="save-box" style="display:none;">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        xmlns="http://www.w3.org/2000/svg"
        class="-translate-y-[0.2px]"
      >

      <!--
        <path
        fill="#6FD1FF"
          d="M13.4873 1.85254C14.2417 2.59961 14.3809 3.63965 14.3809 4.97266V10.4438C14.3809 11.7842 14.2417 12.8096 13.4873 13.564C12.7329 14.3184 11.6929 14.4648 10.3599 14.4648H4.88867C3.55566 14.4648 2.51562 14.3184 1.75391 13.564C1.00684 12.8169 0.867676 11.7842 0.867676 10.4438V4.95801C0.867676 3.63965 1.00684 2.60693 1.76123 1.85254C2.5083 1.10547 3.55566 0.95166 4.8667 0.95166H10.3599C11.6929 0.95166 12.7329 1.09814 13.4873 1.85254ZM4.04639 7.71924C4.04639 8.13672 4.34668 8.42969 4.77148 8.42969H6.91016V10.5757C6.91016 10.9932 7.20312 11.2935 7.62061 11.2935C8.04541 11.2935 8.3457 10.9932 8.3457 10.5757V8.42969H10.4917C10.9092 8.42969 11.2095 8.13672 11.2095 7.71924C11.2095 7.29443 10.9092 6.99414 10.4917 6.99414H8.3457V4.84814C8.3457 4.43066 8.04541 4.12305 7.62061 4.12305C7.20312 4.12305 6.91016 4.43066 6.91016 4.84814V6.99414H4.77148C4.34668 6.99414 4.04639 7.29443 4.04639 7.71924Z"
        ></path>
        -->
        <!-- 水平线段 -->
        <line x1="0" y1="8" x2="15" y2="8" stroke="#6FD1FF" stroke-width="1"/>
        <!-- 垂直线段 -->
        <line x1="8" y1="0" x2="8" y2="15" stroke="#6FD1FF" stroke-width="1"/>
      </svg>
      <span>Add tracks</span>
    </div>
    <div class="music-list">
      <div
        class="music-item"
        v-for="(item, index) in list"
        :key="index"
        @click="playIndexAudio(index)"
      >
        <div v-if="currentIndex === index && isPlaying" class="music-active">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="index" v-else>{{ index + 1 }}</div>
        <div class="music-content">
          <div class="music-content-1">{{ item.name }}</div>
          <p class="music-content-2">
            <img src="@/assets/img/down.png" alt="" />
            <span style="margin-left:10px;margin-right:6px;">{{ item.createDate }}</span>
            <span style="margin-right:6px;" class="music-content-2-circle-span"></span>
            <img src="@/assets/img/up.png" alt="" />
            <span style="margin-left:2px;">{{item.fileSize}}</span>
          </p>
        </div>
        <div v-if="item.isCanDownload==1" class="more" @click.stop="openShareDialog(index)">
          <svg
            width="12"
            height="3"
            viewBox="0 0 12 3"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#E0EDF8"
              d="M2.41016 1.77539C2.41016 1.125 1.88281 0.603516 1.2207 0.603516C0.576172 0.603516 0.0488281 1.125 0.0488281 1.77539C0.0488281 2.42578 0.576172 2.94727 1.2207 2.94727C1.88281 2.94727 2.41016 2.42578 2.41016 1.77539ZM6.74609 1.77539C6.74609 1.125 6.22461 0.603516 5.57422 0.603516C4.92969 0.603516 4.4082 1.125 4.4082 1.77539C4.4082 2.42578 4.92969 2.94727 5.57422 2.94727C6.22461 2.94727 6.74609 2.42578 6.74609 1.77539ZM11.0996 1.77539C11.0996 1.125 10.5781 0.603516 9.92773 0.603516C9.26562 0.603516 8.74414 1.125 8.74414 1.77539C8.74414 2.42578 9.26562 2.94727 9.92773 2.94727C10.5781 2.94727 11.0996 2.42578 11.0996 1.77539Z"
            ></path>
          </svg>
        </div>
      </div>
    </div>
    <!-- 下方音频控制器 -->
    <Control
      v-show="!showDialog && showControl"
      :audioInfo="audioInfo"
      :isPlaying="isPlaying"
      :currentMp3="currentMp3"
      :currentAudio="currentAudio"
      :translateX="translateX"
      @handleTouchStart="handleTouchStart"
      @handleTouchMove="handleTouchMove"
      @handleTouchEnd="handleTouchEnd"
      @pausePlay="pausePlay"
      @playAudio="playAudio"
      @showDialogClick="showDialog = true"
      @shareContent="shareContent"
    />
    <Dialog :value="showDialog" @close="showDialog = false">
      <div
        class="modle-content"
        style="height: 548px; transform: none; transform-origin: 50% 50% 0px"
      >
        <div class="title">{{ audioInfo }}</div>
        <div class="title2">projectg</div>
        <div class="music-bg-box">
          <img src="@/assets/img/music-bg.png" alt="" />
        </div>
        <div class="time-box">
          <div class="progress-box">
            <div class="current-center">
              <div class="move-itme" v-show="isTouchMove">
                {{ moveCurrentTime }} / {{ formattedDuration }}
              </div>
              <div class="center-split" :style="{ height: splitHeight }"></div>
            </div>
            <div class="waveform-mask-box">
              <div class="waveform-mask">
                <div
                  class="waveform"
                  :style="{ transform: `translateX(${translateX}px)` }"
                  @touchstart="handleTouchStart"
                  @touchmove="handleTouchMove"
                  @touchend="handleTouchEnd"
                >
                  <Waveform :audioSrc="currentMp3" />
                </div>
              </div>
            </div>
          </div>
          <div class="audio-time">
            {{ formattedCurrentTime }} / {{ formattedDuration }}
          </div>
        </div>
        <div class="control-box">
          <svg
            style="cursor: pointer;"
            @click="shareContent"
            width="18"
            height="24"
            viewBox="0 0 18 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M8.99023 15.5273C8.50195 15.5273 8.0918 15.1172 8.0918 14.6387V4.80469L8.16992 3.34961L7.55469 4.0625L6.1875 5.52734C6.03125 5.70312 5.79688 5.79102 5.57227 5.79102C5.13281 5.79102 4.77148 5.46875 4.77148 5.00977C4.77148 4.77539 4.86914 4.59961 5.03516 4.43359L8.31641 1.2793C8.54102 1.04492 8.75586 0.966797 8.99023 0.966797C9.23438 0.966797 9.44922 1.04492 9.67383 1.2793L12.9551 4.43359C13.1211 4.59961 13.209 4.77539 13.209 5.00977C13.209 5.46875 12.8477 5.79102 12.4082 5.79102C12.1836 5.79102 11.959 5.70312 11.8027 5.52734L10.4258 4.0625L9.82031 3.34961L9.89844 4.80469V14.6387C9.89844 15.1172 9.48828 15.5273 8.99023 15.5273ZM3.375 23.3105C1.26562 23.3105 0.171875 22.2266 0.171875 20.1465V10.5273C0.171875 8.44727 1.26562 7.36328 3.375 7.36328H6.04102V9.27734H3.49219C2.58398 9.27734 2.08594 9.74609 2.08594 10.6934V19.9805C2.08594 20.9277 2.58398 21.3965 3.49219 21.3965H14.498C15.3965 21.3965 15.9043 20.9277 15.9043 19.9805V10.6934C15.9043 9.74609 15.3965 9.27734 14.498 9.27734H11.9688V7.36328H14.6152C16.7246 7.36328 17.8184 8.44727 17.8184 10.5273V20.1465C17.8184 22.2266 16.7246 23.3105 14.6152 23.3105H3.375Z"
              fill="white"
            ></path>
          </svg>

          <svg
          style="cursor: pointer;"
            @click="playPrevious"
            width="29"
            height="16"
            viewBox="0 0 29 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M12.8438 15.7812C13.6934 15.7812 14.4062 15.1367 14.4062 13.9551V8.33008C14.5234 8.79883 14.875 9.18945 15.4512 9.53125L25.4512 15.4102C25.8711 15.6543 26.2422 15.7812 26.6719 15.7812C27.5215 15.7812 28.2344 15.1367 28.2344 13.9551V1.91406C28.2344 0.732422 27.5215 0.0878906 26.6719 0.0878906C26.2422 0.0878906 25.8809 0.214844 25.4512 0.458984L15.4512 6.33789C14.875 6.67969 14.5234 7.07031 14.4062 7.53906V1.91406C14.4062 0.732422 13.6934 0.0878906 12.8438 0.0878906C12.4141 0.0878906 12.0527 0.214844 11.623 0.458984L1.62305 6.33789C0.890625 6.77734 0.529297 7.28516 0.529297 7.92969C0.529297 8.58398 0.890625 9.10156 1.62305 9.53125L11.623 15.4102C12.043 15.6543 12.4141 15.7812 12.8438 15.7812Z"
              fill="white"
            ></path>
          </svg>

          <svg
          style="cursor: pointer;"
            @click="playAudio"
            v-if="!isPlaying"
            width="12"
            height="14"
            viewBox="0 0 12 14"
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M1.37842 13.1206C1.69336 13.1206 1.95703 13.0107 2.29395 12.8203L10.9219 7.81787C11.5591 7.45166 11.8081 7.17334 11.8081 6.71924C11.8081 6.26514 11.5591 5.98682 10.9219 5.62061L2.29395 0.625488C1.95703 0.427734 1.69336 0.317871 1.37842 0.317871C0.785156 0.317871 0.367676 0.771973 0.367676 1.49707V11.9414C0.367676 12.6665 0.785156 13.1206 1.37842 13.1206Z"
            ></path>
          </svg>
          <svg
          style="cursor: pointer;"
            v-else
            @click="pausePlay"
            width="11"
            height="14"
            viewBox="0 0 11 14"
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M1.30371 13.9048H3.24609C4.03467 13.9048 4.43311 13.498 4.43311 12.7178V1.3042C4.43311 0.499023 4.03467 0.125488 3.24609 0.117188H1.30371C0.523438 0.117188 0.116699 0.523926 0.116699 1.3042V12.7178C0.108398 13.498 0.515137 13.9048 1.30371 13.9048ZM7.5874 13.9048H9.52979C10.3101 13.9048 10.7168 13.498 10.7168 12.7178V1.3042C10.7168 0.499023 10.3101 0.117188 9.52979 0.117188H7.5874C6.79883 0.117188 6.40039 0.523926 6.40039 1.3042V12.7178C6.40039 13.498 6.79883 13.9048 7.5874 13.9048Z"
            ></path>
          </svg>

          <svg
          style="cursor: pointer;"
            @click="playNext"
            width="29"
            height="16"
            viewBox="0 0 29 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M2.32812 15.7812C2.75781 15.7812 3.12891 15.6543 3.54883 15.4102L13.5488 9.53125C14.125 9.18945 14.4766 8.79883 14.5938 8.33008V13.9551C14.5938 15.1367 15.3066 15.7812 16.166 15.7812C16.5859 15.7812 16.957 15.6543 17.377 15.4102L27.377 9.53125C28.1094 9.10156 28.4805 8.58398 28.4805 7.92969C28.4805 7.28516 28.1191 6.77734 27.377 6.33789L17.377 0.458984C16.9473 0.214844 16.5859 0.0878906 16.1562 0.0878906C15.3066 0.0878906 14.5938 0.732422 14.5938 1.91406V7.53906C14.4766 7.07031 14.125 6.67969 13.5488 6.33789L3.54883 0.458984C3.11914 0.214844 2.75781 0.0878906 2.32812 0.0878906C1.47852 0.0878906 0.765625 0.732422 0.765625 1.91406V13.9551C0.765625 15.1367 1.47852 15.7812 2.32812 15.7812Z"
              fill="white"
            ></path>
          </svg>

          <svg
          style="cursor: pointer;"
            v-if="playType === 'normal'"
            @click="
              playType = 'loop';
              $refs.toast.show('列表循环');
            "
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M1.26367 10.3105C0.697266 10.3105 0.257812 9.87109 0.257812 9.30469V8.25C0.257812 5.42773 2.25 3.69922 5.35547 3.69922H12.7578V1.51172C12.7578 1.0332 13.041 0.769531 13.5098 0.769531C13.7246 0.769531 13.9199 0.837891 14.0859 0.974609L17.9141 4.16797C18.2852 4.48047 18.2754 4.95898 17.9141 5.26172L14.0859 8.45508C13.9199 8.58203 13.7246 8.66016 13.5098 8.66016C13.041 8.66016 12.7578 8.38672 12.7578 7.91797V5.67188H5.20898C3.41211 5.67188 2.25977 6.74609 2.25977 8.44531V9.30469C2.25977 9.87109 1.83008 10.3105 1.26367 10.3105ZM20.7266 9.38281C21.293 9.38281 21.7324 9.82227 21.7324 10.3887V11.4531C21.7324 14.2656 19.7402 15.9941 16.6348 15.9941H9.23242V18.2012C9.23242 18.6699 8.94922 18.9434 8.48047 18.9434C8.26562 18.9434 8.07031 18.8652 7.9043 18.7383L4.07617 15.5352C3.70508 15.2324 3.70508 14.7539 4.07617 14.4512L7.9043 11.248C8.07031 11.1211 8.26562 11.043 8.48047 11.043C8.94922 11.043 9.23242 11.3164 9.23242 11.7852V14.0312H16.7812C18.5781 14.0312 19.7305 12.957 19.7305 11.2578V10.3887C19.7305 9.82227 20.1602 9.38281 20.7266 9.38281Z"
              fill="white"
            ></path>
          </svg>

          <svg
          style="cursor: pointer;"
            v-if="playType === 'loop'"
            @click="
              playType = 'loopOne';
              $refs.toast.show('单曲循环');
            "
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M1.26367 10.3105C0.697266 10.3105 0.257812 9.87109 0.257812 9.30469V8.25C0.257812 5.42773 2.25 3.69922 5.35547 3.69922H12.7578V1.51172C12.7578 1.0332 13.041 0.769531 13.5098 0.769531C13.7246 0.769531 13.9199 0.837891 14.0859 0.974609L17.9141 4.16797C18.2852 4.48047 18.2754 4.95898 17.9141 5.26172L14.0859 8.45508C13.9199 8.58203 13.7246 8.66016 13.5098 8.66016C13.041 8.66016 12.7578 8.38672 12.7578 7.91797V5.67188H5.20898C3.41211 5.67188 2.25977 6.74609 2.25977 8.44531V9.30469C2.25977 9.87109 1.83008 10.3105 1.26367 10.3105ZM20.7266 9.38281C21.293 9.38281 21.7324 9.82227 21.7324 10.3887V11.4531C21.7324 14.2656 19.7402 15.9941 16.6348 15.9941H9.23242V18.2012C9.23242 18.6699 8.94922 18.9434 8.48047 18.9434C8.26562 18.9434 8.07031 18.8652 7.9043 18.7383L4.07617 15.5352C3.70508 15.2324 3.70508 14.7539 4.07617 14.4512L7.9043 11.248C8.07031 11.1211 8.26562 11.043 8.48047 11.043C8.94922 11.043 9.23242 11.3164 9.23242 11.7852V14.0312H16.7812C18.5781 14.0312 19.7305 12.957 19.7305 11.2578V10.3887C19.7305 9.82227 20.1602 9.38281 20.7266 9.38281Z"
              fill="#F9E749"
            ></path>
          </svg>

          <svg
          style="cursor: pointer;"
            v-if="playType === 'loopOne'"
            @click="
              playType = 'normal';
              $refs.toast.show('关闭循环');
            "
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
          >
            <path
              d="M20.7461 7.36133C20.1211 7.36133 19.7305 7 19.7305 6.32617V2.3418H19.6328L18.6465 3.11328C18.5 3.23047 18.3535 3.28906 18.1777 3.28906C17.7871 3.28906 17.5234 3.02539 17.5234 2.66406C17.5234 2.41992 17.6016 2.24414 17.875 2.03906L19.1738 1.05273C19.623 0.710938 19.9844 0.525391 20.5605 0.525391C21.2637 0.525391 21.752 1.01367 21.752 1.74609V6.32617C21.752 7 21.3711 7.36133 20.7461 7.36133ZM1.25391 10.3105C0.667969 10.3105 0.248047 9.89062 0.248047 9.30469V8.25C0.248047 5.42773 2.24023 3.69922 5.3457 3.69922H10.3848V1.51172C10.3848 1.0332 10.668 0.769531 11.1367 0.769531C11.3516 0.769531 11.5469 0.837891 11.7031 0.974609L15.541 4.16797C15.9121 4.48047 15.9121 4.94922 15.541 5.26172L11.7031 8.45508C11.5469 8.58203 11.3516 8.66016 11.1367 8.66016C10.668 8.66016 10.3848 8.38672 10.3848 7.91797V5.67188H5.19922C3.40234 5.67188 2.25 6.74609 2.25 8.44531V9.30469C2.25 9.89062 1.83984 10.3105 1.25391 10.3105ZM20.7168 9.38281C21.3027 9.38281 21.7227 9.80273 21.7227 10.3887V11.4531C21.7227 14.2656 19.7305 15.9941 16.625 15.9941H9.22266V18.2012C9.22266 18.6699 8.93945 18.9434 8.4707 18.9434C8.25586 18.9434 8.06055 18.8652 7.89453 18.7383L4.06641 15.5352C3.69531 15.2324 3.69531 14.7539 4.06641 14.4512L7.89453 11.248C8.06055 11.1211 8.25586 11.043 8.4707 11.043C8.93945 11.043 9.22266 11.3164 9.22266 11.7852V14.0312H16.7715C18.5684 14.0312 19.7207 12.957 19.7207 11.2578V10.3887C19.7207 9.80273 20.1309 9.38281 20.7168 9.38281Z"
              fill="#F9E749"
            ></path>
          </svg>
        </div>
      </div>
    </Dialog>
    <!-- 分享弹窗 -->
    <Dialog :value="showShareDialog" style="background: rgb(0, 0, 0, 0.5)">
      <div class="share-dialog">
        <div class="share-close">
          <div class="close" @click="showShareDialog = false">
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              xmlns="http://www.w3.org/2000/svg"
              class="mx-auto"
            >
              <path
                d="M1.10205 12.3809C0.787109 12.0732 0.794434 11.5312 1.10205 11.231L5.60645 6.71924L1.10205 2.21484C0.794434 1.91455 0.787109 1.37988 1.10205 1.05762C1.41699 0.742676 1.95166 0.75 2.25928 1.05762L6.76367 5.56201L11.2681 1.05762C11.583 0.75 12.103 0.75 12.4253 1.06494C12.7476 1.37256 12.7402 1.90723 12.4326 2.21484L7.92822 6.71924L12.4326 11.231C12.7402 11.5386 12.7402 12.0659 12.4253 12.3809C12.1104 12.7031 11.583 12.6958 11.2681 12.3882L6.76367 7.88379L2.25928 12.3882C1.95166 12.6958 1.42432 12.6958 1.10205 12.3809Z"
              ></path>
            </svg>
          </div>
        </div>
        <div class="share-info">
          <div class="borer-info">Info</div>
          <div class="share-name">{{ shareInfo }}</div>
          <div class="share-time">{{ shareFormattedDuration }}</div>
          <div class="share-waveform">
            <Waveform :svgWidth="252" :audioSrc="shareAudioSrc" />
          </div>
        </div>
        
        <div class="share-btn-box">
          <!--
          <div class="share-item" @click="copyClick">
            <svg
              width="16"
              height="20"
              viewBox="0 0 16 20"
              xmlns="http://www.w3.org/2000/svg"
              class="h-20 w-16"
            >
              <path
                d="M13.0459 15.2637V11.1035C13.0459 10.0195 12.8555 9.11133 12.0571 8.31299L7.9043 4.16016C7.09131 3.34717 6.24902 3.0835 5.11377 3.0835H3.98584V2.73193C3.98584 1.22314 4.82812 0.395508 6.35889 0.395508H10.292V4.75342C10.292 5.49316 10.7534 5.95459 11.5005 5.95459H15.6021V12.8613C15.6021 14.4946 14.6938 15.2637 13.2803 15.2637H13.0459ZM11.6909 4.96582C11.3979 4.96582 11.2881 4.84863 11.2881 4.55566L11.2808 0.681152C11.5298 0.717773 11.7568 0.893555 12.0278 1.17188L14.9941 4.21875C15.2725 4.49707 15.4556 4.72412 15.4849 4.96582H11.6909ZM2.74805 19.021C1.18066 19.021 0.367676 18.2007 0.367676 16.6187V6.54785C0.367676 4.97314 1.18066 4.14551 2.74805 4.14551H5.37744V9.53613C5.37744 10.4736 5.83887 10.9351 6.76904 10.9351H11.9766V16.6187C11.9766 18.1934 11.1709 19.021 9.60352 19.021H2.74805ZM6.84229 9.86572C6.58594 9.86572 6.44678 9.72656 6.44678 9.46289V4.33594C6.75439 4.42383 7.04736 4.65088 7.38428 4.99512L11.2368 8.91357C11.5737 9.25781 11.7642 9.55078 11.8447 9.86572H6.84229Z"
              ></path>
            </svg>
            <span>Copy Link</span>
          </div>
          <div class="share-item" @click="shareContent">
            <svg
              width="15"
              height="18"
              viewBox="0 0 15 18"
              xmlns="http://www.w3.org/2000/svg"
              class="h-18 w-15"
            >
              <path
                d="M7.48145 11.6455C7.11523 11.6455 6.80762 11.3379 6.80762 10.979V3.60352L6.86621 2.51221L6.40479 3.04688L5.37939 4.14551C5.26221 4.27734 5.08643 4.34326 4.91797 4.34326C4.58838 4.34326 4.31738 4.10156 4.31738 3.75732C4.31738 3.58154 4.39062 3.44971 4.51514 3.3252L6.97607 0.959473C7.14453 0.783691 7.30566 0.725098 7.48145 0.725098C7.66455 0.725098 7.82568 0.783691 7.99414 0.959473L10.4551 3.3252C10.5796 3.44971 10.6455 3.58154 10.6455 3.75732C10.6455 4.10156 10.3745 4.34326 10.0449 4.34326C9.87646 4.34326 9.70801 4.27734 9.59082 4.14551L8.55811 3.04688L8.104 2.51221L8.1626 3.60352V10.979C8.1626 11.3379 7.85498 11.6455 7.48145 11.6455ZM3.27002 17.4829C1.68799 17.4829 0.867676 16.6699 0.867676 15.1099V7.89551C0.867676 6.33545 1.68799 5.52246 3.27002 5.52246H5.26953V6.95801H3.35791C2.67676 6.95801 2.30322 7.30957 2.30322 8.02002V14.9854C2.30322 15.6958 2.67676 16.0474 3.35791 16.0474H11.6123C12.2861 16.0474 12.667 15.6958 12.667 14.9854V8.02002C12.667 7.30957 12.2861 6.95801 11.6123 6.95801H9.71533V5.52246H11.7002C13.2822 5.52246 14.1025 6.33545 14.1025 7.89551V15.1099C14.1025 16.6699 13.2822 17.4829 11.7002 17.4829H3.27002Z"
              ></path>
            </svg>
            <span>Share</span>
          </div>
          -->
        </div>
        
        <div class="export-trak" @click="donwload">
          <svg
            width="12"
            height="14"
            viewBox="0 0 12 14"
            xmlns="http://www.w3.org/2000/svg"
            class="w-[20px]"
          >
            <path
              d="M1.15137 11.6997C0.70459 11.6997 0.382324 12.0366 0.382324 12.4907C0.382324 12.9448 0.70459 13.2817 1.15137 13.2817H10.8267C11.2808 13.2817 11.6104 12.9448 11.6104 12.4907C11.6104 12.0366 11.2808 11.6997 10.8267 11.6997H6.16846C6.32959 11.6704 6.47607 11.5825 6.59326 11.458L11.2734 6.82178C11.4492 6.646 11.5371 6.44824 11.5371 6.23584C11.5371 5.78174 11.2075 5.45215 10.7681 5.45215C10.541 5.45215 10.3359 5.54736 10.1895 5.69385L8.68066 7.17334L6.7251 9.31934L6.79834 7.78125V0.962402C6.79834 0.479004 6.46875 0.149414 6 0.149414C5.52393 0.149414 5.19434 0.479004 5.19434 0.962402V7.78125L5.26758 9.32666L3.31201 7.17334L1.81055 5.69385C1.65674 5.54004 1.45898 5.45215 1.23193 5.45215C0.785156 5.45215 0.462891 5.78174 0.462891 6.23584C0.462891 6.44824 0.543457 6.646 0.719238 6.82178L5.39941 11.458C5.5166 11.5825 5.66309 11.6704 5.82422 11.6997H1.15137Z"
            ></path>
          </svg>
          <span>Download track</span>
        </div>
      </div>
    </Dialog>

    <Dialog :value="projectBase!=null&&projectBase.needPassword!=null&&projectBase.needPassword==1" style="background: rgb(0, 0, 0, 0.5)">
      <div class="share-dialog">
        <div class="share-info">
          <div class="borer-info2">This link need password</div>
          <div class="borer-info-password">
            <input
              type="password"
              id="password"
              v-model="shareLinkPassword"
              placeholder="Please enter password"
            />
          </div>
        </div>
        
        <div class="share-btn-box">
          
        </div>
        
        <div class="export-trak-btn-password" @click="submitPassword">
          
          <span>Confirm password</span>
        </div>
      </div>
    </Dialog>

    <Toast ref="toast" />
  </div>
</template>

<script>
import Dialog from "@/components/dialog.vue";
import Control from "@/components/control.vue";
import Waveform from "@/components/waveform.vue";
// import zaijian from "@/assets/audio/再见伴奏.mp3";
// import tiankong from "@/assets/audio/蔡依林-jolin-tsai---天空.mp3";
import Toast from "@/components/toast2.vue";
import { getAudioList,recordTrack } from "@/api/api";

export default {
  data() {
    return {
      showShareDialog: false, // 显示分享弹窗
      showDialog: false, // 是否显示播放弹窗
      isPlaying: false, // 是否正在播放
      currentAudio: null, // 当前的音频对象
      currentTime: 0, // 当前播放时间
      duration: 0, // 音频总时长
      startX: 0, // 记录触摸起始位置
      currentX: 0, // 记录当前滑动位置
      translateX: 0, // 当前 DOM 的 translateX 值
      maxLeft: -450, // 最多往左滑动距离
      maxRight: 0, // 最多往右滑动距离（根据左滑动调整）
      progress: 0, // 滑动的比例
      isTouchMove: false, // 是否正在滑动
      splitHeight: "42px",
      moveTime: 0, // 滑动时的时间点
      list: [
        // 假数据
        // { audio: zaijian, name: "再见伴奏" },
        // { audio: tiankong, name: "蔡依林 Jolin Tsai - 天空" },
        // // 网络数据demo
        // { audio: 'https://lx-sycdn.kuwo.cn/0c0f88e60c8d5f2390e962d33bcdc460/673f51c3/resource/n1/75/94/748118034.mp3', name: 'APT' }
      ],
      currentMp3: null, // 当前的音频文件
      showControl: false, // 是否显示下方控制台
      audioInfo: "", // 音频信息 暂时只有音频名 可以自己更改数据结构
      currentIndex: -1, // 当前播放的音频index
      shareAudio: null, // 当前待分享的音频
      shareInfo: "", // 分享弹窗的音频名
      shareAudioSrc: "", //待分享的音频
      shareDuration: 0, // 分享弹窗的音乐时长
      playType: "normal", // 循环模式
      isSwitching: false, // 防止快速切换音频
      projectBase: {},
      shareLinkPassword:"",
      isLoadProjectCoverImgComplete:false //加载project封面完成
    };
  },
  components: {
    Dialog,
    Waveform,
    Control,
    Toast,
  },
  mounted() {
    const params = new URLSearchParams(window.location.search);
    const linkKey = params.get('linkKey');
    const appId = params.get('f');
    // console.log("param:"+linkKey)
    this.getList(linkKey,appId);
    this.$bus.$on("complete", this.compelte);
  },
  beforeDestroy() {
    this.$bus.$off("complete", this.compelte);
  },
  computed: {
    // 格式化当前播放时间
    formattedCurrentTime() {
      return this.formatTime(this.currentTime);
    },
    // 格式化滑动时间
    moveCurrentTime() {
      return this.formatTime(this.moveTime);
    },
    // 格式化音频总时长
    formattedDuration() {
      return this.formatTime(this.duration);
    },
    shareFormattedDuration() {
      return this.formatTime(this.shareDuration);
    },
  },
  methods: {
    getList(linkKey,appId) {
      getAudioList({"linkKey":linkKey,"appId":appId,"password":this.shareLinkPassword}).then((res) => {
        // list: [
        //   { audio: 网络音频地址比如http://www.baidu.com/123.mp3, name: "再见伴奏" },
        //   { audio: 网络音频地址比如http://www.baidu.com/123.mp3, name: "蔡依林 Jolin Tsai - 天空" },
        // ],
        // console.log("看看数据："+JSON.stringify(res.data) )
        this.list = res.data.trackList;
        this.projectBase = res.data;
        if(res.data.needPassword==1&&res.data.passwordErrorTips){
          this.$toast(res.data.passwordErrorTips);
        }
      });
    },
    playPrevious() {
      if (this.playType === "loop" || this.playType === "loopOne") {
        this.playType = "loop";
        if (this.currentIndex > 0 && !this.isSwitching) {
          const index = this.currentIndex - 1; // 切换到上一首
          this.playIndexAudio(index);
        } else {
          const index = this.list.length - 1;
          this.playIndexAudio(index);
        }
        return;
      }

      if (this.currentIndex > 0 && !this.isSwitching) {
        const index = this.currentIndex - 1; // 切换到上一首
        this.playIndexAudio(index);
      } else {
        console.log("已经是第一首了！");
      }
    },
    playNext() {
      if (this.playType === "loop" || this.playType === "loopOne") {
        this.playType = "loop";
        if (this.currentIndex < this.list.length - 1 && !this.isSwitching) {
          const index = this.currentIndex + 1; // 切换到下一首
          this.playIndexAudio(index);
        } else {
          const index = 0;
          this.playIndexAudio(index);
        }
        return;
      }
      if (this.currentIndex < this.list.length - 1 && !this.isSwitching) {
        const index = this.currentIndex + 1; // 切换到下一首
        this.playIndexAudio(index);
      } else {
        console.log("已经是最后一首了");
      }
    },
    async donwload() {
      try {
        // 请求音频文件并获取数据
        const response = await fetch(this.shareAudioSrc);
        if (!response.ok) throw new Error("音频文件下载失败");
        const blob = await response.blob();

        // 创建动态 URL
        const url = URL.createObjectURL(blob);

        // 创建动态 <a> 标签
        const link = document.createElement("a");
        link.href = url;
        link.download = `${this.shareInfo}.mp3`; // 设置下载的文件名

        // 触发下载
        link.click();

        // 释放 URL 资源
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("下载音频失败:", error);
      }
    },
    async copyClick() {
      const textToCopy = location.href; // 需要复制的内容
      try {
        if (navigator.clipboard && navigator.clipboard.writeText) {
          // 使用现代 Clipboard API
          await navigator.clipboard.writeText(textToCopy);
          this.$toast("链接已复制！");
        } else {
          // 使用备用方案
          this.fallbackCopyText(textToCopy);
          this.$toast("链接已复制！");
        }
      } catch (err) {
        console.error("复制失败：", err);
        this.$toast("复制失败，请手动复制！");
      }
    },
    fallbackCopyText(text) {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      textarea.style.position = "fixed";
      textarea.style.width = "0";
      textarea.style.height = "0";
      textarea.style.opacity = "0";
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        const successful = document.execCommand("copy");
        if (!successful) throw new Error("Fallback copy failed");
      } catch (err) {
        console.error("Fallback copy failed:", err);
      } finally {
        document.body.removeChild(textarea);
      }
    },
    // 打开歌曲分享弹窗 获取歌曲相关信息
    openShareDialog(index) {
      this.shareInfo = this.list?.[index]?.name;
      this.shareAudioSrc = this.list?.[index]?.audio;
      if (this.shareAudio) {
        this.shareAudio.src = this.shareAudioSrc;
        this.shareAudio.load();
      } else {
        this.shareAudio = new Audio(this.shareAudioSrc);
      }
      this.shareAudio.addEventListener("loadedmetadata", this.shareLoadAudio);
      this.showShareDialog = true;
    },
    // 监听load方法获取待分享音频总时长
    shareLoadAudio() {
      this.shareDuration = this.shareAudio.duration;
    },
    // 根据索引播放音频
    playIndexAudio(index) {
      if (typeof index !== 'number') return;
      if (this.isSwitching) return;
      // 防止快速切换音频导致报错
      this.isSwitching = true;
      if (this.currentIndex === index) {
        if (this.isPlaying) {
          this.pausePlay();
          this.isSwitching = false;
        } else {
          this.playAudio(index);
        }
        return;
      }

      this.currentMp3 = this.list[index].audio;
      this.openFixedControlDom(this.currentMp3, this.list[index].name, index);
    },
    // 当前播放音频波形图甲加载完成
    compelte() {
      this.showControl = true;
    },
    // 分享
    async shareContent() {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "music", // 页面标题
            text: "music", // 自定义文本
            url: window.location.href, // 当前页面的链接
          });
          console.log("分享成功！");
        } catch (error) {
          console.error("用户取消分享或分享失败:", error);
        }
      } else {
        alert("您的设备不支持系统分享功能！");
      }
    },
    // 触摸开始
    handleTouchStart(event) {
      this.startX = event.touches[0].clientX;
    },
    // 触摸移动
    handleTouchMove(event) {
      this.isTouchMove = true;
      this.splitHeight = "52px";
      const deltaX = event.touches[0].clientX - this.startX;
      let newTranslateX = this.translateX + deltaX;

      // 限制滑动范围 [-450, 0]
      if (newTranslateX > 0) {
        newTranslateX = 0; // 超出右边界时固定在 0
      } else if (newTranslateX < -450) {
        newTranslateX = -450; // 超出左边界时固定在 -450
      }

      // 仅在合法范围内更新
      this.translateX = newTranslateX;

      // 计算滑动比例
      this.progress = Math.abs(this.translateX) / 450;

      const audio = this.currentAudio;
      if (audio) {
        // 如果滑动到边界，特殊处理时间
        if (this.translateX === -450) {
          this.moveTime = audio.duration - 1; // 总时长 -1 秒
        } else {
          this.moveTime = this.progress * audio.duration; // 根据滑动比例更新时间
        }
      }

      // 更新起始位置为当前点
      this.startX = event.touches[0].clientX;
    },
    // 触摸结束
    handleTouchEnd() {
      // 更新音频时间
      const audio = this.currentAudio; // 确保 audio 元素绑定了 ref="audio"
      if (audio) {
        // 如果滑动到边界，特殊处理时间
        if (this.translateX === -450) {
          audio.currentTime = audio.duration - 1; // 总时长 -1 秒
        } else {
          audio.currentTime = this.progress * audio.duration; // 根据滑动比例更新时间
        }
      }
      this.isTouchMove = false;
      this.splitHeight = "42px";
      // 如果需要额外处理滑动结束后的逻辑，可以在这里实现
      // 例如：吸附效果、回弹效果等
    },
    // 点击列表上方的播放
    playList() {
      if (this.currentIndex >= 0) {
        if (this.isPlaying) {
          console.log("看看索引："+this.currentIndex )

          this.pausePlay();
          
        } else {
          this.playAudio();
        }
      } else {
        this.openFixedControlDom();
      }
    },
    // 播放方法
    openFixedControlDom(audio, audioInfo, index) {
      if (this.currentAudio) {
        // 暂停当前音频
        this.currentAudio.pause();
        this.currentAudio.currentTime = 0;

        // 清理事件监听器
        this.currentAudio.removeEventListener(
          "loadedmetadata",
          this.loadedmetadata
        );
        this.currentAudio.removeEventListener("timeupdate", this.timeupdatge);
        this.currentAudio.removeEventListener("ended", this.ended);
      }

      // 切换音频
      if (audio && audioInfo) {
        this.audioInfo = audioInfo;

        if (this.currentAudio) {
          this.currentAudio.src = audio;
        } else {
          this.currentAudio = new Audio(audio);
        }

        // 绑定新的事件监听器
        this.currentAudio.addEventListener(
          "loadedmetadata",
          this.loadedmetadata
        );
        this.currentAudio.addEventListener("timeupdate", this.timeupdatge);
        this.currentAudio.addEventListener("ended", this.ended);

        // 播放音频
        this.playAudio(index);
      } else if (!this.currentAudio && this.list?.length > 0) {
        // 第一次播放
        this.audioInfo = this.list?.[0]?.name;
        this.currentMp3 = this.list?.[0]?.audio;

        this.currentAudio = new Audio(this.currentMp3);

        // 绑定新的事件监听器
        this.currentAudio.addEventListener(
          "loadedmetadata",
          this.loadedmetadata
        );
        this.currentAudio.addEventListener("timeupdate", this.timeupdatge);
        this.currentAudio.addEventListener("ended", this.ended);

        // 播放音频
        this.playAudio();
      }
    },
    // 播放结束
    ended() {
      // 记录index
      const index = this.currentIndex;
      this.isPlaying = false;
      this.translateX = 0;
      this.duration = 0;
      this.currentTime = 0;
      if (this.playType === "loop") {
        this.playNext();
      }
      if (this.playType === "loopOne") {
        this.playAudio(index);
      }
    },
    // 加载播放时长
    loadedmetadata() {
      this.duration = this.currentAudio.duration;
    },
    // 时间更新
    timeupdatge() {
      this.currentTime = this.currentAudio.currentTime;
      this.duration = this.currentAudio.duration;
      // 滑动时不滚动
      if (this.isTouchMove) {
        return;
      }
      const progress = this.currentTime / this.currentAudio.duration; // 播放进度 (0 ~ 1)
      this.translateX = progress * this.maxLeft; // 根据进度计算 translateX
    },
    // 播放音频
    async playAudio(index) {
      console.log("playAudio index:"+index )
      if (index && typeof index !== 'number') return;
      if (!this.currentAudio) return;

      try {
        // 确保 `play()` 不被打断
        await this.currentAudio.load();
        await this.currentAudio.play();
        this.isPlaying = true;
        this.currentIndex = index || 0;
        console.log(this.currentIndex);
        
        setTimeout(() => {
          this.isSwitching = false;
        }, 100);
      } catch (error) {}

      //record track
      try{
        console.log("record track:"+this.list[index].linkKey)
        recordTrack({"linkKey":this.list[index].linkKey})
        
      }catch(error){}

    },
    // 暂停音频
    pausePlay() {
      if (this.currentAudio) {
        this.currentAudio.pause();
        this.isPlaying = false;
      }
    },
    // 格式化时间为 mm:ss 格式
    formatTime(seconds) {
      const mins = Math.floor(seconds / 60)
        .toString()
        .padStart(2, "0");
      const secs = Math.floor(seconds % 60)
        .toString()
        .padStart(2, "0");
      return `${mins}:${secs}`;
    },
    //project 封面图加载完成
    loadProjectCoverImgComplete(){
      console.log("project封面图加载完成...." )
      this.isLoadProjectCoverImgComplete = true;
    },
    //跳转下载链接
    goToDownload(){
      window.location.href = this.projectBase.downloadUrl;
    },
    submitPassword() {
      if(!this.shareLinkPassword||this.shareLinkPassword==""){
        this.$toast("password cannot be empty");
        return;
      }
      this.list = [];
      const params = new URLSearchParams(window.location.search);
      const linkKey = params.get('linkKey');
      const appId = params.get('f');
      // console.log("param:"+linkKey)
      this.getList(linkKey,appId);
      this.$bus.$on("complete", this.compelte);
    },
  },
};
</script>

<style lang="less" scoped>
.music {
  max-width: 700px;
  padding: 0 24px;
  background-color: #131521;

  .node-dis{
    display: block;
  }
  .node-hidden{
    display: none;
  }

  .top-btn-replace{
    margin-top:24px;
  }

  .top-btn {
    position: sticky;
    left: 0;
    top: 0;
    padding: 24px;
    display: flex;
    // justify-content: space-between;
    justify-content: center; //子元素居中
    text-align: center;
    font-weight: 500;
    z-index: 99;
    //渐变
    // background: linear-gradient(
    //   180deg,
    //   #fff 28.13%,
    //   #fffffffd 36.6%,
    //   #fffffff7 43.49%,
    //   #ffffffed 49.06%,
    //   #ffffffe0 53.52%,
    //   #ffffffd1 57.14%,
    //   #ffffffbf 60.15%,
    //   #ffffffab 62.8%,
    //   #ffffff96 65.33%,
    //   #ffffff80 67.97%,
    //   #ffffff6a 70.98%,
    //   #ffffff53 74.6%,
    //   #ffffff3d 79.07%,
    //   #ffffff27 84.63%,
    //   #ffffff13 91.53%,
    //   #fff0
    // );

    .left,
    .right {
      cursor: pointer;
      // width: 48%;
      font-size: 20px;
      // border-radius: 9999px;
      padding: 12px 8px;
      width: 50%;
      min-width: 200px;
      height: auto;
      
    }

    .left {
      color: #fff;
      background: linear-gradient(180deg, #242424, #101010);
      box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000,
        0px 4px 15px rgba(0, 0, 0, 0.15);
    }
    // .right {
    //   color: #191919;
    //   background: hsla(0, 0%, 10%, 0.05);
    // }
    .right {
      color: #FFFFFF;
      // background: hsla(0, 0%, 10%, 0.05);
      background: url("../assets/img/download.png");
      background-size: contain; /* 保持图片比例，使其适应容器 */
      background-position: center; /* 图片居中显示 */
      background-repeat: no-repeat; /* 背景图片不重复 */
    }
  }

  .bg-img-box {
    position: relative;
    padding: 0 24px;
    margin-bottom: 24px;
    // width: calc(100vw - 48px);

    img.music-bg-img {
      transition: left 0.3s;
      position: relative;
      left: 0;
      width: 100%;
      height: auto;
      border-radius: 16px;
      z-index: 3;
    }

    img.music-bg-img-playing {
      transition: left 0.3s;
      left: -24px;
    }

    .disc {
      transition: left 0.3s;
      position: absolute;
      width: calc(100% - 48px);
      top: 0;
      left: 24px;
      z-index: 2;
      // clip-path: circle(50%); /* 裁剪形状为圆形 */
      border-radius: 9999px;
      overflow: hidden;

      .disc-bg {
        width: 100%;
        height: 100%;
        background-color: rgba(201, 193, 216, 0.7);
        z-index: 1;
        position: absolute;
        left: 0;
        top: 0;
      }

      img {
        background-color: rgba(201, 193, 216, 0.7);
        z-index: 0;
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        object-fit: cover; /* 确保图片不变形 */
      }
    }

    .disc-playing {
      transition: left 0.3s;
      left: 48px;
    }
  }

  .main-play {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .main-left {
      div {
        margin-bottom: 10px;
        font-size: 28px;
        color:#FFFFFF;
      }
      p {
        font-size: 17px;
        color: #8890A5;
        display: flex;
        align-items: center; /* 垂直居中 */

        img{
          width: 21px;
          height: 21px;
          margin-right: 9px;
        }
      }
    }

    .main-right {
      cursor: pointer;
      width: 44px;
      height: 44px;
      // border-radius: 16px;
      border-radius: 50%;
      align-items: center;
      background: linear-gradient(180deg, #fdfdfd, #fffefe);
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff),
        var(--tw-ring-shadow, 0 0 #fff), var(--tw-shadow);
      color: var(--buttonLabelPrimary);
      display: flex;
      justify-content: center;
      box-shadow: var(--tw-ring-offset-shadow, 0 0 #fff),
        var(--tw-ring-shadow, 0 0 #fff), var(--tw-shadow);
    }
  }

  .save-box {
    border-radius: 12px;
    margin-top: 20px;
    height: 44px;
    // background: hsla(0, 0%, 10%, 0.05);
    background: #242B37;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-family: IBM Plex Mono, sans-serif;
      margin-left: 12px;
      font-size: 15px;
      // color: #191919;
      color: #6FD1FF;
    }
  }
  .music-list {
    margin-top: 20px;
    .music-item {
      display: flex;
      height: 60px;
      align-items: center;

      .music-active {
        align-items: center;
        display: flex;
        height: 18px;
        margin: 0 auto;
        width: 30px;

        span {
          display: inline-block; /* 确保 span 是块状 */
          animation: bounce 2s ease infinite alternate;
          background-color: #7e7e7e;
          border-radius: 3px;
          margin: 0 0.5px;
          width: 2px !important; /* 必须显式设置宽度 */
          height: 100%; /* 根据父容器高度自动调整 */

          &:nth-of-type(2) {
            animation-delay: -2.2s; /* 延迟 */
          }

          &:nth-of-type(3) {
            animation-delay: -3.7s;
          }

          &:nth-of-type(4) {
            animation-delay: -1.7s;
          }

          &:nth-of-type(5) {
            animation-delay: -2.6s;
          }

          &:nth-of-type(6) {
            animation-delay: -0.7s;
          }
        }
      }

      .index {
        width: 30px;
        font-size: 17px;
        color: #FFFF;
      }

      .music-content {
        flex: 1;
        // :nth-child(1) {
        .music-content-1{
          color: #FFF;
          font-size: 17px;
          margin-bottom: 4px;
        }
        // :nth-child(2) {
        .music-content-2{
          display: flex;
          align-items: center; /* 垂直居中 */
          color: #8890A5;
          font-size: 15px;
          // background-color: red;

          img {
            width: 12px;
            height: 12px;
          }

          .music-content-2-circle-span{
             width: 4px; /* 设置宽度 */
             height: 4px; /* 设置高度 */
             background-color: #8890A5; /* 设置背景颜色 */
             border-radius: 50%; /* 设置圆角为50%，使元素变成圆形 */
          }
        }
      }

      .more {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
        width: 28px;
        height: 16px;
        cursor: pointer;
        background-color:#1A2A43;
        border-radius: 5px 5px;
      }
    }
  }

  .modle-content {
    min-width: 330px;
    max-width: 330px;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background: linear-gradient(180deg, #36363680, #02020280), #020202cc;
    padding-top: 20px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    overflow: hidden;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    pointer-events: auto;

    .title {
      font-size: 17px;
      color: #fff;
      text-align: center;
    }

    .title2 {
      font-size: 15px;
      letter-spacing: -0.24px;
      line-height: 20px;
      margin: 0;
      color: #7e7e7e;
      text-align: center;
    }

    .music-bg-box {
      will-change: width, height, border-radius, transform;
      width: 280px;
      height: 280px;
      border-radius: 16px;
      margin: 0 auto;
      overflow: hidden;
      margin-top: 8px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .time-box {
      margin-top: 35px;
      .progress-box {
        display: flex;
        align-items: center;
        justify-content: center;
        touch-action: none;
        width: 100%;
        position: relative;

        .waveform-mask-box {
          width: 100%;
          mask-image: linear-gradient(
              90deg,
              transparent 0,
              #000 25%,
              #000 75%,
              transparent
            ),
            linear-gradient(
              270deg,
              transparent 0,
              #000 25%,
              #000 75%,
              transparent
            );
        }

        .waveform-mask {
          position: relative;
          z-index: 9;
          mask-image: linear-gradient(90deg, #fff 0 50%, #fff6 0, #fff6);
        }

        .waveform {
          position: relative;
          user-select: none;
          cursor: grab;
          color: #fff;
          padding-left: 50%;
          width: 1000px;
        }

        .current-center {
          position: absolute;
          width: 100%;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: visible;
          height: 52px;
          // z-index: 1;
          .move-itme {
            position: absolute;
            top: -22px;
            padding: 5px 10px;
            background: #f9e749;
            color: #000;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .center-split {
            width: 2px;
            background-color: #f9e749;
          }
        }
      }

      .audio-time {
        color: #fff;
        text-align: center;
        margin-top: 15px;
      }
    }

    .control-box {
      margin-top: 25px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
  }

  .share-dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    max-height: 90vh;
    border-radius: 16px;
    padding: 24px;
    background-color: #fff;
    box-sizing: border-box;

    .share-close {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .close {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 16px;
        background: hsla(0, 0%, 10%, 0.05);
      }
    }

    .share-info {
      position: relative;
      margin-top: 20px;
      // width: 302px;
      // height: 140px;
      padding: 12px 24px;
      border: 1px solid hsla(0, 0%, 10%, 0.1);
      border-radius: 24px;
      text-align: center;

      .borer-info {
        position: absolute;
        padding: 3px 8px;
        background: #fff;
        left: 17px;
        top: -12px;
        font-size: 13px;
        color: #7e7e7e;
        letter-spacing: -0.52px;
        line-height: 18px;
        font-family: IBM Plex Mono, sans-serif;
      }

      .borer-info2 {
        position: absolute;
        padding: 3px 8px;
        background: #fff;
        left: 17px;
        top: -12px;
        font-size: 16px;
        color: #7e7e7e;
        letter-spacing: -0.52px;
        line-height: 18px;
        font-family: IBM Plex Mono, sans-serif;
      }
      .borer-info-password{
        // background-color: red;
        margin-top: 20px;
        margin-bottom: 20px;

        input{
          border: 1px solid #7e7e7e;
          height: 30px;
          font-size: 14px;
          padding:3px 10px;
          border-radius: 5px;
          margin-left: 0px;
          margin-right: 0px;
        }

        input:focus {
          /* 清除聚焦样式 */
          outline: none;
          border: 1px solid #7e7e7e;
          border-radius: 5px;
          box-shadow: none;
        }
      }


      .share-name {
        line-height: 28px;
        font-size: 22px;
        color: #191919;
        margin-bottom: 5px;
      }

      .share-time {
        font-size: 13px;
        color: #7e7e7e;
        margin-bottom: 20px;
        font-family: IBM Plex Mono, sans-serif;
      }

      .share-waveform {
        color: #7e7e7e;
      }
    }

    .share-btn-box {
      margin-top: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      .share-item {
        cursor: pointer;
        width: 47%;
        height: 64px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: #191919;
        background-color: hsla(0, 0%, 10%, 0.05);
        border-radius: 16px;
        font-weight: bold;

        span {
          margin-top: 6px;
        }
      }
    }
    .export-trak-btn-password{
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 16px;
      justify-content: center;
      height: 44px;
      font-size: 15px;
      color: #191919;
      background-color: hsla(0, 0%, 10%, 0.05);
      margin-left: 40px;
      margin-right: 40px;
      svg {
        margin: 0 18px;
      }
    }

    .export-trak {
      cursor: pointer;
      display: flex;
      align-items: center;
      border-radius: 16px;
      height: 44px;
      font-size: 15px;
      color: #191919;
      background-color: hsla(0, 0%, 10%, 0.05);

      svg {
        margin: 0 18px;
      }
    }
  }
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3);
  }

  30% {
    transform: scaleY(1);
  }
  60% {
    transform: scaleY(0.5);
  }
  80% {
    transform: scaleY(0.75);
  }
  100% {
    transform: scaleY(0.6);
  }
}
</style>