import request from './request'

export function getAudioList(params_json) {
  return request({
    url: '/api/voice/up/get/data',
    method: 'post',
    data: params_json
  })
}


export function recordTrack(params_json) {
  return request({
    url: '/api/voice/up/record/track',
    method: 'post',
    data: params_json
  })
}
