<template>
  <div class="control-bottom">
    <div class="control-left">
      <div class="btn" @click="isPlaying ? pausePlay() : playAudio();">
        <svg
          v-show="!isPlaying"
          width="12"
          height="14"
          viewBox="0 0 12 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <path
            d="M1.37842 13.1206C1.69336 13.1206 1.95703 13.0107 2.29395 12.8203L10.9219 7.81787C11.5591 7.45166 11.8081 7.17334 11.8081 6.71924C11.8081 6.26514 11.5591 5.98682 10.9219 5.62061L2.29395 0.625488C1.95703 0.427734 1.69336 0.317871 1.37842 0.317871C0.785156 0.317871 0.367676 0.771973 0.367676 1.49707V11.9414C0.367676 12.6665 0.785156 13.1206 1.37842 13.1206Z"
            fill="#000"
          ></path>
        </svg>

        <svg
          v-show="isPlaying"
          width="11"
          height="14"
          viewBox="0 0 11 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        >
          <path
            d="M1.30371 13.9048H3.24609C4.03467 13.9048 4.43311 13.498 4.43311 12.7178V1.3042C4.43311 0.499023 4.03467 0.125488 3.24609 0.117188H1.30371C0.523438 0.117188 0.116699 0.523926 0.116699 1.3042V12.7178C0.108398 13.498 0.515137 13.9048 1.30371 13.9048ZM7.5874 13.9048H9.52979C10.3101 13.9048 10.7168 13.498 10.7168 12.7178V1.3042C10.7168 0.499023 10.3101 0.117188 9.52979 0.117188H7.5874C6.79883 0.117188 6.40039 0.523926 6.40039 1.3042V12.7178C6.40039 13.498 6.79883 13.9048 7.5874 13.9048Z"
            fill="#000"
          ></path>
        </svg>
      </div>
      <div class="name-box" @click="showDialogClick">
        <div class="name">{{audioInfo}}</div>
        <div class="sec-name"></div>
      </div>
    </div>

    <!-- 波形图 -->
    <div class="waveform-control-box">
      <div class="split"></div>
      <div class="waveform-mask-box">
        <div class="waveform-mask">
          <div
            class="waveform"
            :style="{ transform: `translateX(${translateX}px)` }"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
          >
            <Waveform v-if="currentMp3" :audioSrc="currentMp3" />
          </div>
        </div>
      </div>
    </div>

    <!-- 分享 -->
    <!--
    <div class="share-btn" @click="$emit('shareContent')">
      <img src="@/assets/img/share_tracks.png" alt="" />
    </div>
    -->
  </div>
</template>

<script>
import Waveform from "@/components/waveform.vue";
export default {
  props: {
    audioInfo: {
      type: String, 
      default: ""
    },
    currentAudio: {
      type: HTMLAudioElement,
      default: null,
    },
    currentMp3: {
      type: String,
      default: "",
    },
    translateX: {
      type: Number,
      default: 0,
    },
    isPlaying: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Waveform,
  },
  methods: {
    playAudio() {
      this.$emit("playAudio");
    },
    pausePlay() {
      this.$emit("pausePlay");
    },
    // 触摸开始
    handleTouchStart(event) {
      this.$emit("handleTouchStart", event);
    },
    // 触摸移动
    handleTouchMove(event) {
      this.$emit("handleTouchMove", event);
    },
    // 触摸结束
    handleTouchEnd() {
      this.$emit("handleTouchEnd");
    },
    showDialogClick() {
      console.log("hide dialog..." )
      // this.$emit('showDialogClick')
    },
  },
};
</script>

<style lang="less" scoped>
.control-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  max-width: calc(700px - 48px);
  width: calc(100vw - 48px);
  // border-radius: 28px;
  border-radius: 18px;
  bottom: 13px;
  height: 56px;
  // background: linear-gradient(180deg, #36363680, #02020280), #020202cc;
  // background: linear-gradient(180deg, #202f4280, #02020280), #202d3fcc;
  background-color: #203852;
  border: #33628D 2px solid;

  .control-left {
    display: flex;
    align-items: flex-start;
    color: #191919;

    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
      border-radius: 50%;
      width: 44px;
      height: 44px;
      // background: url("../assets/img/music-bg.png");
      background-color: #fff;
      background-repeat: no-repeat; /* 不重复 */
      background-position: center center; /* 居中 */
      background-size: cover; /* 铺满 */
      transform: rotate(3.87196deg); /* 旋转角度 */
      transform-origin: center; /* 旋转中心 */

      svg {
        transform: rotate(-3.87196deg); /* 旋转角度 */
      }
    }

    .name-box {
      margin-right: 12px;
      margin-top: 7px;
      margin-left: 9px;
      .name {
        color: #fff;
        font-size: 16px;
      }

      .sec-name {
        // color: #7e7e7e;
        margin-top: 4px;
        color:#fff;
        font-size: 12px;
      }
    }
  }

  .waveform-control-box {
    position: relative;
    flex: 1;
    overflow: hidden;
    .split {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 90%;
      width: 2px;
      background-color: #f9e749;
    }

    .waveform-mask-box {
      mask-image: linear-gradient(
          90deg,
          transparent 0,
          #000 25%,
          #000 75%,
          transparent
        ),
        linear-gradient(270deg, transparent 0, #000 25%, #000 75%, transparent);
    }

    .waveform-mask {
      position: relative;
      z-index: 9;
      mask-image: linear-gradient(90deg, #fff 0 50%, #fff6 0, #fff6);
    }

    .waveform {
      position: relative;
      user-select: none;
      cursor: grab;
      color: #fff;
      padding-left: 50%;
      width: 1000px;
    }
  }

  .share-btn {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: red;

    img{
      width:40px;
      height: 40px;
    }
  }
}
</style>